:root {
  --main-color: #3885AA;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100vh;
}

.spinner {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--main-color);
  border-top-color: transparent;
  animation: spin 1.2s linear infinite;
}

.pulser {
  width: 50px;
  height: 50px;
  background-color: var(--main-color);
  border-radius: 100%;
  position: relative;
  opacity: 0;
  transition: opacity 1s ease;
  animation: fade-in 2s ease infinite;
}

.pulser:first-child {
  animation-delay: 0s;
}
.pulser:nth-child(2) {
  animation-delay: 100ms;

}
.pulser:nth-child(3) {
  animation-delay: 250ms;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}