.divRecintos {
    background-color: 32CD32;
}

aux {
 position: absolute;
 display: flex;
}

.helperRow {
 flex-flow: row nowrap;
 top: -25px;
}

.helperCol {
    flex-flow: column nowrap;
    left: -25px;
}

rowaux, colaux {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.font-weight-bold {
    font-weight: 600;
}