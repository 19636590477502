// Here you can add other styles
button a {
  text-decoration: none;
  color: #fff;
}
.item { height: 100%; }
.dropzone { outline: solid 1px; height: 50px; }
.draggable-dropzone--occupied { background: lightgreen; }

.mydiv {
    position: absolute;
    z-index: 9;
    background-color: transparent;
    text-align: center;
  }

.mydiv div[data-containerid] {
  display: flex;
  flex-flow: row nowrap;
}
.mydiv span {
  min-width: 1.2rem;
  max-width: 20px;
  width: 20px;
  height: 20px;
  max-height: 1.7rem;
  font-size: 11px;
  background-repeat: no-repeat;
  background-position: center;
}

div.tools-container {
  display: flex;
  flex-flow: column;
  gap: 0;
  background-color: #fff;
  border-radius: 5% 0;
  border: solid 1px #000;
}
div.tools-container p {
  margin-bottom: 0;
  cursor: pointer;
}
.div-yellow span {
  border: solid 1px #F2CC0D;
  color: #F2CC0D;
  background-image: url(../../public/assets/Seat-yellow.svg);
}
.div-orange span {
  border: solid 1px #FA8C05;
  color: #FA8C05;
  background-image: url(../../public/assets/Seat-orange.svg);
}
.div-green  span{
  border: solid 1px #87C734;
  color: #87C734;
  background-image: url(../../public/assets/Seat-green.svg);
}
.div-purple span {
  border: solid 1px #800080;
  color: #800080;
  background-image: url(../../public/assets/Seat-purple.svg);
}
.mydiv div span.selected{
  background-image: url(../../public/assets/Seat-selected.svg);
}
.mydiv div span[data-estado='Bloqueado'] {
  background-image: url(../../public/assets/Seat.svg);
}
.mydiv div span[data-estado='Vendido'],
.mydiv div span[data-estado='Validado'] {
  background-image: url(../../public/assets/Seat-selected.svg);
}
.mydiv div span[data-reducedmobility] {
  background-image: url(../../public/assets/ReducedMobility.svg);
}

.mydiv div span[data-reducedmobility].selected {
  background-image: url(../../public/assets/ReducedMobility-selected.svg);
}
.mydiv div span[data-reducedmobility][data-estado='Bloqueado'],
.mydiv div span[data-reducedmobility][data-estado='Validado'],
.mydiv div span[data-reducedmobility][data-estado='Vendido'] {
  background-image: url(../../public/assets/ReducedMobility-blocked.svg);
}
.mydiv div span[data-estado='Invitacion'] {
  background-image: url(../../public/assets/Seat-blue.svg);
}
.mydiv div span[data-reducedmobility][data-estado='Invitacion'] {
  background-image: url(../../public/assets/ReducedMobility-invitation.svg);
}
.mydiv[data-preview] span {
  background-image: none !important;
}
.stageContainer {
  text-align: center;
  background-color: #d3d3d3;
  color: #fff;
  position: relative;
  width: 100%;
}

.canvasContainer {
  position: relative;
  overflow: scroll;
  margin-top: 1rem;
  height: 40rem;
}

span[data-bs-content] {
  cursor: pointer;
}
span[data-zonaname='Bloqueada'] {
  cursor: not-allowed;
  pointer-events: none;
  border: none !important;
}

.noInputFile input[type="file"] {
  display: none;
}

fieldset.col-3 {
  border: solid 1px #000;
  background: #fff;
  padding: 1% 3%;
  border-radius: 15px;
  margin: 0;
}

.input-group.input-group-sm.mb-3 > label.input-group-text {
  width: 80px;
}

div.input-group.input-group-sm.mb-3 > div.input-group-text.intercaladoCheck-container{
  gap: 5px;
  padding: 0 max(20px);
}

div.input-group.input-group-sm.mb-3 >label.input-group-text.startingRow {
  width: 128px;
}

.tablaOverflow {
  overflow: scroll;
}

span.selected {
  border: none !important;
}

span[data-estado='Reservado'],
span[data-estado='Pendiente'],
span[data-estado='Rechazado'] {
  background-image: url(../../public/assets/Seat.svg);
  border: none !important;
  cursor: not-allowed !important;
  pointer-events: none;
}

span[data-estado='Vendido'],
span[data-estado='Validado'],
span[data-estado='Invitacion'] {
  border: none !important;
  cursor: not-allowed !important;
  pointer-events: none;
}
span[data-estado='Bloqueado'] {
  border-color: #000 !important;
}

.noStyle {
  border: none;
  background-color: transparent;
  color: rgba(44, 56, 74, 0.95);
  padding: 0.45rem;
}

.noStyle:active {
  transform: scale(1.2, 1.2);
}

.unstyledLi {
  list-style: none;
}

.separator {
  margin: 10px 0;
}

.zonaDatos {
  color: #fff;
  font-family: 'Open Sans';
  font-size: 14px;
  border-radius: 5px;
  margin: 5px 2px 0px;
  position: relative;
}
.zonasRow {
  margin: 10px 0;
}

.cartModal {
  position: fixed;
  right: 30px;
  bottom: 50px;
  border-radius: 50% 50% 0 50%;
}
.entrada-container {
  display: flex;
  justify-content: space-evenly;
  flex-flow: row nowrap;
}
.entrada-container div {
  display: flex;
  flex-flow: column nowrap;
}

.devolution-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  gap: 15px;
}
.modal-footer {
  justify-content: space-between;
}
.carritoContainer {
  display: flex;
  flex-flow: column;
  gap: 15px;
}
.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  gap: 15px;
}

.container-lg {
  max-width: 100% !important;
}

div.seatInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  min-height: 100px;
}
div.seatInfo span {
  background-color: #FFFFFF;
  border: solid 1px #721c24;
  border-radius: .25rem;
  margin: .25rem 0;
  padding: 1.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: #721c24;
}
.color-group {
  justify-content: space-around;
}
.seatExample {
  margin: auto !important;
  width: 100%;
}
input[type="radio"].color {
  display: none;
}
.color-label {
  width: 25px;
  height: 25px;
  display: inline-block;
  padding: 10px;
  border-radius: 50% !important;
  cursor: pointer;
}
input[type="radio"]:checked + label {
  border: 2px solid black;
}

.yellow {
  background-color: #F2CC0D;
}
.orange {
  background-color: #FA8C05;
}
.green {
  background-color: #87C734;
}
.purple {
  background-color: #800080;
}
.deleteZone {
  position: absolute;
  font-size: 25px;
  right: 10px;
  top: 0;
}
.deleteZone:hover {
  cursor: pointer;
}
.MediaExplorer, .imageContainer {
  display: flex;
  flex-flow: column;
  gap: 5px;
  align-items: center;
}
.imagesContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 200px;
  max-height: 800px;
  overflow-y: auto;
}
.passHeaders {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  text-align: center;
}
.passActivo {
  width: 45px;
}
// span[data-bs-content] {
//   background-image: url(../../public/assets/Seat.svg);
// }
.freeHand {
  // height: 100%;
  // width: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  background-color: rgba(255, 255, 255, 0);
  cursor: crosshair;
  z-index: 999;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative
}

.h-full{
  height: 100%;
}

.w-full{
  width: 100%;
}

.container-up{
  z-index: 100;
}
.container-down{
  z-index: 1;
}

.top-0 {
  top: 0;
}

.chartNumbers {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.widgetVentas {
  color: #fff;
  background-color: #3399ff;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 0.5rem;
  text-align: center;
}
.widgetEntradas {
  color: #fff;
  background-color: #f00;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 0.5rem;
  text-align: center;
}
.widgetInvitaciones {
  color: #fff;
  background-color: #7349AC;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 0.5rem;
  text-align: center;
}
.mediaExplorerContainer {
  display: flex;
  flex-flow: row;
  gap: .5rem;
}
.gap-10 {
  gap: 10px;
}

.duplicated-sells-table > *:nth-child(4n+1),
.duplicated-sells-table > *:nth-child(4n+2) {
  background-color: rgb(179, 170, 170); /* Color for 1st and 2nd children in each group */
}

.duplicated-sells-table > *:nth-child(4n+3),
.duplicated-sells-table > *:nth-child(4n+4) {
  background-color: rgb(201, 201, 219); /* Color for 3rd and 4th children in each group */
}
.duplicated-sells-table .mb-10 {
  margin-bottom: 10px;
}